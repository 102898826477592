body {
  margin: 0;
  padding: 0;
  font-family: 'Nanum Pen Script', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  -webkit-hyphens: auto;
          hyphens: auto;
  line-height: 1.65;
  margin: 0 auto;
  max-width: 660px;
  padding: 54px 0 0;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
h1 {
  padding: 0;
  margin: 0;
  font-size: 60px;
  line-height: 75px;
}

p {
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #616161;
}

.slackButton {
  padding: 42px 0;
}

.donateButton {
  margin-top: 15px;
  padding: 15px 20px;
  background-color: #000;
  color: #fff;
  margin: 0 auto;
  font-size: 26px;
  cursor: pointer;
}

.wojakImage {
  width: 350px;
  padding-bottom: 42px;
  margin: 0 auto;
}

